import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_PROXY_URL,
});

const transformConfig = (config) => {
  if (transformConfig.config) {
    Object.assign(config, transformConfig.config);
  }
  if (transformConfig.headers) {
    Object.assign(config.headers, transformConfig.headers);
  }
  if (transformConfig.fn.length) {
    transformConfig.fn.forEach((fn) => fn(config));
  }
};
transformConfig.fn = [];
transformConfig.config = {};
transformConfig.headers = {};

const callAxios = function (config) {
  if (!config) config = {};
  if (!config.headrs) config.headers = {};

  transformConfig(config);

  return instance(config);
};

const makeBiProxy = (method) => {
  return function axiosBiProxy(url, config) {
    if (!config) config = {};
    return callAxios({ url, method, ...config });
  };
};

const makeTriProxy = (method) => {
  return function axiosTriProxy(url, data, config) {
    if (!config) config = {};
    return callAxios({ url, method, data, ...config });
  };
};

const proxy = function axiosProxy(urlOrConfig, config) {
  if (typeof urlOrConfig === "string" && config) {
    return callAxios({ url: urlOrConfig, ...config });
  } else if (typeof urlOrConfig === "string") {
    return callAxios({ url: urlOrConfig });
  } else {
    return callAxios(urlOrConfig);
  }
};

proxy.request = callAxios;
proxy.get = makeBiProxy("get");
proxy.delete = makeBiProxy("delete");
proxy.head = makeBiProxy("head");
proxy.post = makeTriProxy("post");
proxy.put = makeTriProxy("put");
proxy.patch = makeTriProxy("patch");

proxy.transformConfig = transformConfig;

export default proxy;
